.leaflet-container {
    height: 100%;
}
.foodkub-leaflet-div-icon {
    background: transparent;
    border: none;
}

.leaflet-control-attribution {
    display: none;
}