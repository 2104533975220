.time-selection {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    border-radius: 4px;
    border-bottom: 0px;
    cursor: pointer;
}

.full-width {
    width: 100%;
}

.time-selection-label {
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    transform: translate(14px, -6px) scale(0.75);
    transform-origin: top left;
    pointer-events: none;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.time-selection-content {
    padding: 14px 14px;
    font-size: 1.1rem;
}

.time-selection-fieldset {
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
}

.time-selection-legend {
    max-width: 1000px;
    transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    width: auto;
    height: 11px;
    display: block;
    padding: 0;
    font-size: 1.1em;
    text-align: left;
    visibility: hidden;
}