.scroll-inner {
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.box-three {
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid #000;
    float: left;
    margin: 0 10px 0 0;
    overflow: scroll;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.touch {
    padding: 16px;
    border: 1px solid #000;
    width: 100%;
    height: 100vh;
    margin-right: 20px;
    overflow-y: scroll; /* has to be scroll, not auto */
    float: left;
    -webkit-overflow-scrolling: touch;
}