
.pincode-input-text {
    border: 1px solid grey;
    border-radius: 0.3rem;
    font-size: 21px;
    outline: none;
    height: 45px;
    outline: none;
    text-align: center;
    transition-duration: 250ms;
    transition-property: color, border, box-shadow, transform;
    width: 45px;
    margin: 4px;
}
.pincode-input-text:focus {
    border-color: #1abc9c;
    box-shadow: 0 0 0.25rem #1abc9c;
    outline: none;
}

.pin-field {
    border: 1px solid grey;
    border-radius: 0.3rem;
    font-size: 21px;
    height: 45px;
    font-weight: bold;
    outline: none;
    text-align: center;
    transition-duration: 250ms;
    transition-property: color, border, box-shadow, transform;
    width: 45px;
    margin: 4px;
}
.pin-field-error {
    border: 1px solid red;
    border-radius: 0.3rem;
    font-size: 21px;
    height: 45px;
    outline: none;
    text-align: center;
    transition-duration: 250ms;
    transition-property: color, border, box-shadow, transform;
    width: 45px;
    margin: 4px;
}
.pin-field:focus {
    border-color: #1abc9c;
    box-shadow: 0 0 0.25rem #1abc9c;
    outline: none;
}
.pin-field:focus + .pin-field {
    border-left-color: #1abc9c;
}
.pin-field.complete {
    border-color: green;
    color: green;
}
.pin-field.complete[disabled] {
    background: rgba(0, 128, 0, 0.1);
    cursor: not-allowed;
    opacity: 0.5;
}
.pin-field.complete + .pin-field {
    border-left-color: green;
}