.link-selection {
    border: 1px solid;
    color: #1abc9c;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    border-radius: 4px;
}

.full-width {
    width: 100%;
}

.link-content {
    padding: 4px 8px;
    font-size: 1.1rem;
    color: #1abc9c;
}